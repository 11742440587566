import React from "react";
import "./Fretboard.css";
import  a_major  from "../diagrams/a_major.jpg";
import  a_minor from "../diagrams/a_minor.jpg";
import  ab_major from "../diagrams/ab_major.jpg";
import  ab_minor from "../diagrams/ab_minor.jpg";
import  B_major from "../diagrams/B_major.jpg";
import  B_minor from "../diagrams/B_minor.jpg";
import  Bb_major from "../diagrams/Bb_major.jpg";
import  Bb_minor from "../diagrams/Bb_minor.jpg";
import  c_major from "../diagrams/c_major.jpg";
import  c_minor from "../diagrams/c_minor.jpg";
import  d_major from "../diagrams/d_major.jpg";
import  d_minor from "../diagrams/d_minor.jpg";
import  db_major from "../diagrams/db_major.jpg";
import  db_minor from "../diagrams/db_minor.jpg";
import  e_major from "../diagrams/e_major.jpg";
import  e_minor from "../diagrams/e_minor.jpg";
import  eb_major from "../diagrams/eb_major.jpg";
import  eb_minor from "../diagrams/eb_minor.jpg";
import  f_major from "../diagrams/f_major.jpg";
import  f_minor from "../diagrams/f_minor.jpg";
import  g_major from "../diagrams/g_major.jpg";
import  g_minor from "../diagrams/g_minor.jpg";
import  gb_major from "../diagrams/gb_major.jpg";
import  gb_minor from "../diagrams/gb_minor.jpg";

import { Card, Image } from "semantic-ui-react";

class Fretboard extends React.Component {

    getScale(scale) {
        switch(scale) {
            case '0_0': return 'C Minor';
            case '0_1': return 'C Major';
            case '1_0': return 'C♯/D♭ Minor';
            case '1_1': return 'C♯/D♭ Major';
            case '2_0': return 'D Minor';
            case '2_1': return 'D Major';
            case '3_0': return 'D♯/E♭ Minor';
            case '3_1': return 'D♯/E♭ Major';
            case '4_0': return 'E Minor';
            case '4_1': return 'E Major';
            case '5_0': return 'F Minor';
            case '5_1': return 'F Major';
            case '6_0': return 'F♯/G♭ Minor';
            case '6_1': return 'F♯/G♭ Major';
            case '7_0': return 'G Minor';
            case '7_1': return 'G Major';
            case '8_0': return 'G♯/A♭ Minor';
            case '8_1': return 'G♯/A♭ Major';
            case '9_0': return 'A Minor';
            case '9_1': return 'A Major';
            case '10_0': return 'A♯/B♭ Minor';
            case '10_1': return 'A♯/B♭ Major';
            case '11_0': return 'B Minor';
            case '11_1': return 'B Major';
            default: return undefined;
        }
    }

    getMajorFretboard(scale) {
        switch(scale) {
            case '0_0': return c_minor;
            case '0_1': return c_major;
            case '1_0': return db_minor;
            case '1_1': return db_major;
            case '2_0': return d_minor;
            case '2_1': return d_major;
            case '3_0': return eb_minor;
            case '3_1': return eb_major;
            case '4_0': return e_minor;
            case '4_1': return e_major;
            case '5_0': return f_minor;
            case '5_1': return f_major;
            case '6_0': return gb_minor;
            case '6_1': return gb_major;
            case '7_0': return g_minor;
            case '7_1': return g_major;
            case '8_0': return ab_minor;
            case '8_1': return ab_major;
            case '9_0': return a_minor;
            case '9_1': return a_major;
            case '10_0': return Bb_minor;
            case '10_1': return Bb_major;
            case '11_0': return B_minor;
            case '11_1': return B_major;
            default: return undefined;
        }
    }

    getRelativeMinorFretboard(scale) {
        switch(scale) {
            case '0_0': return eb_major;
            case '0_1': return a_minor;
            case '1_0': return e_major;
            case '1_1': return Bb_minor;
            case '2_0': return f_major;
            case '2_1': return B_minor;
            case '3_0': return gb_major;
            case '3_1': return c_minor;
            case '4_0': return g_major;
            case '4_1': return db_minor;
            case '5_0': return ab_major;
            case '5_1': return d_minor;
            case '6_0': return a_major;
            case '6_1': return eb_minor;
            case '7_0': return Bb_major;
            case '7_1': return e_minor;
            case '8_0': return B_major;
            case '8_1': return f_minor;
            case '9_0': return c_major;
            case '9_1': return gb_minor;
            case '10_0': return db_major;
            case '10_1': return g_minor;
            case '11_0': return d_major;
            case '11_1': return ab_minor;
            default: return undefined;
        }
    }

    getRelativeMinor(scale) {
        switch(scale) {
            case '0_0': return 'D♯/E♭ Major';
            case '0_1': return 'A Minor';
            case '1_0': return 'E Major';
            case '1_1': return 'A♯/B♭ Minor';
            case '2_0': return 'F Major';
            case '2_1': return 'B Minor';
            case '3_0': return 'F♯/G♭ Major';
            case '3_1': return 'C Minor';
            case '4_0': return 'G Major';
            case '4_1': return 'C♯/D♭ Minor';
            case '5_0': return 'G♯/A♭ Major';
            case '5_1': return 'D Minor';
            case '6_0': return 'A Major';
            case '6_1': return 'D♯/E♭ Minor';
            case '7_0': return 'A♯/B♭ Major';
            case '7_1': return 'E Minor';
            case '8_0': return 'B Major';
            case '8_1': return 'F Minor';
            case '9_0': return 'C Major';
            case '9_1': return 'F♯/G♭ Minor';
            case '10_0': return 'C♯/D♭ Major';
            case '10_1': return 'G Minor';
            case '11_0': return 'D Major';
            case '11_1': return 'G♯/A♭ Minor';
            default: return undefined;
        }
    }

    getFretboardCard() {
        return (
            <Card className="fretboard" fluid>
                <Image src={this.getMajorFretboard(this.props.scale)} wrapped ui={false} />
                <Card.Content>
                    <Card.Header>{this.getScale(this.props.scale)}</Card.Header>
                    <Card.Meta>Relative Scale: {this.getRelativeMinor(this.props.scale)}</Card.Meta>
                </Card.Content>
            </Card>
        );
    }ß

    getOldFretboard() {
        return (
            <div className="fretboard">
                <div className="relative-1">
                    <h4>Key</h4>
                    <p>{this.getScale(this.props.scale)}</p>
                    <img src={this.getMajorFretboard(this.props.scale)} alt={"No Scale"} ></img>
                </div>
                {/* <div className="relative-2">
                    <h4>Relative Scale</h4>
                    <p>{this.getRelativeMinor(this.props.scale)}</p>
                    <img src={this.getRelativeMinorFretboard(this.props.scale)} alt={"No Scale"} ></img>
                </div> */}
            </div>
        );

    }

    render() {
        if (this.props.scale) {
            return this.getFretboardCard();
        } else {
            return (
                <div className="no-fretboard">No Scale</div>
            );
        }
    }
}

export default Fretboard;