import React from "react";
import { Card } from "semantic-ui-react";
import "./Rhythm.css";

class Rhythm extends React.Component {

    getPitchClass(value) {
        switch(value) {
          case 0:
              return 'C';
          case 1:
              return 'C♯/D♭';
          case 2:
              return 'D';
          case 3:
              return 'D♯/E♭';
          case 4:
              return 'E';
          case 5:
              return 'F';
          case 6:
              return 'F♯/G♭';
          case 7:
              return 'G';
          case 8:
              return 'G♯/A♭';
          case 9:
              return 'A';
          case 10:
              return 'A♯/B♭';
          case 11:
              return 'B';
          default:
              return value;
        }
    }

    getMode(mode) {
        return mode ? 'Major' : 'Minor';
    }

    getOldRhythm() {
        return (
            <div className="rhythm">
                <div className="rhythm-child-1">
                    <h4>{'Time Signature'}</h4>
                    <p>{this.props.time_signature}/4</p>
                </div>
                <div className="rhythm-child-2">
                    <h4>{'Tempo'}</h4>
                    <p>{Math.round(this.props.tempo)} BPM</p>
                </div>
            </div>
        );
    }

    getRhythmCard() {
        return (
            <Card className="rhythm" fluid>
                <Card.Content>
                    <Card.Header>Rhythm</Card.Header>
                    <Card.Description>
                        <table>
                            <thead>
                                <tr>
                                    <th>Meter</th>
                                    <th>Tempo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{this.props.time_signature}/4</td>
                                    <td>{Math.round(this.props.tempo)} BPM</td>
                                </tr>
                            </tbody>
                        </table>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }

    render() {
        if (this.props.time_signature) {
            return this.getRhythmCard();
        }
        else {
            return <div className="no-rhythm">No Rhythm Info Available</div>
        }
    }
}

export default Rhythm;