import React from "react";
import "./Playback.css";
import logo from "../logo.png";

import { Button, ButtonGroup, Card, Icon, Image, Popup } from "semantic-ui-react";

class Playback extends React.Component {

    onPrevClick() {
        this.props.player.previousTrack();
    }

    onPlayClick() {
        this.props.player.togglePlay();
        this.props.togglePlay();
        this.props.toggleTimer();
    }

    onNextClick() {
        this.props.player.nextTrack();
    }

    getEmailCard() {
        return (
            <Card>
                <Card.Content>
                    <Card.Header>Got suggestions?</Card.Header>
                    <Card.Description>
                        <Icon name='idea' iconPosition='left' />
                        Email me at jamalong.live@gmail.com!
                        {/* <Input icon='idea' iconPosition='left' /> */}
                    </Card.Description>
                </Card.Content>
          </Card>
        );
    }

    getPositionStyle() {
        return {width: (this.props.songPosition / this.props.songDuration * 100) + '%'}
    }

    render() {
        return (
            <div className="spotify-controls">
                <Image src={this.props.playingTrack?.album.images[1].url} alt={"No Cover Art"} size="small"/>
  
                {this.props.subscriptionType === 'premium' && 
                    <div className="playing-track">
                        <ButtonGroup basic fluid>
                            <Button icon onClick={() => this.onPrevClick()}>
                                <Icon circular inverted name='fast backward' />
                            </Button>
                            <Button icon onClick={() => this.onPlayClick()}>
                                <Icon circular inverted name={this.props.playing ? 'pause' : 'play'} />
                            </Button>
                            <Button icon onClick={() => this.onNextClick()}>
                                <Icon circular inverted name='fast forward' />
                            </Button>
                        </ButtonGroup>
    
                        <div className="progress">
                            <div className="progress__bar" style={this.getPositionStyle()}/>
                        </div>
                    </div>
                }

                {(this.props.subscriptionType === 'open' || this.props.subscriptionType === 'free') &&
                    <div style={{display:'grid', margin: 'auto', columnGap: '20%', gridTemplateColumns: 'auto auto auto'}} >
                        <div className="progress-free">
                            <div className="progress__bar" style={this.getPositionStyle()}/>
                        </div>
                    </div>
                }

                <div className="logo">
                    <Popup content={this.getEmailCard()}
                        trigger={ 
                            <Image 
                            src={logo} alt="Logo"
                            // as='a'
                            // href="mailto:jfuentes16942@gmail.com"
                            // target='_blank'
                            />
                        } basic on={['click']}
                    />
                </div>
            </div>
        );
    }
}

export default Playback;