export const authEndpoint = "https://accounts.spotify.com/authorize";
export const audioFeaturesEndpoint = "https://api.spotify.com/v1/audio-features";
const redirectUri = "https://jamalong.live/"; // "http://localhost:3000/"; // "https://jam-along-33b03.web.app/";
const clientId = "c882ee87d6184ec4ad5eb2660749575b";

const scopes = [
  "app-remote-control",
  "streaming",
  "user-modify-playback-state",
  "user-read-currently-playing",
  "user-read-email",
  "user-read-playback-position",
  "user-read-playback-state",
  "user-read-private",
  "user-read-recently-played",
  "user-top-read",
  // "playlist-read-collaborative",
  // "user-library-read",
];

export const getAccessTokenFromUrl = () => {
  return window.location.hash
    .substring(1)
    .split("&")
    .reduce((initial, item) => {
      let parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);
      return initial;
    }, {});
};

var generateRandomString = function (length) {
  var text = '';
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const loginUrl = `${authEndpoint}?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${scopes.join(
  "%20"
)}&response_type=token&show_dialog=true&state=${encodeURIComponent(generateRandomString(16))}`;