import React from "react";
import "./App.css";
import SpotifyWebApi from "spotify-web-api-js";
import { getAccessTokenFromUrl, loginUrl } from "./spotify";
import Rhythm from "./Rhythm/Rhythm";
import Fretboard from "./Fretboard/Fretboard";
import Chords from "./Chords/Chords";
import Playback from "./Playback/Playback";
import ChordProgressions from "./ChordProgessions/ChordProgressions";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import 'semantic-ui-css/semantic.min.css'
import { Card } from "semantic-ui-react";

var spotifyApi;
var accessToken;

var counterIsRunning = false;
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyABOMu0XK0088cS3Lt2ZaHLf19cY6f6724",
  authDomain: "jam-along-33b03.firebaseapp.com",
  projectId: "jam-along-33b03",
  storageBucket: "jam-along-33b03.appspot.com",
  messagingSenderId: "892769574860",
  appId: "1:892769574860:web:e11b032c7fa068c4f44769",
  measurementId: "G-6RDQLLL0B0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      deviceId: "",
      loggedIn: false,
      error: "",
      trackName: "anything on Spotify! An app ",
      artistName: "Jesse Fuentes",
      albumName: "Album Name",
      playing: false,
      songPosition: null,
      songDuration: null,
      playingTrack: null,
      checked_tracks: {},
      apiTimestamp: null,
      subscriptionType: null,
    };

    // Intervals
    this.playerCheckInterval = null;
    this.timer = null;

    this.togglePlay = this.togglePlay.bind(this);
    this.toggleTimer = this.toggleTimer.bind(this);
  }

  getAndSaveToken() {
    accessToken = getAccessTokenFromUrl();
    // console.log('getAndSaveToken:', accessToken);
    if (accessToken.access_token) {
      this.handleLogin();
    }
  }

  handleLogin() {
    const { token } = this.state;
    if (accessToken?.access_token !== token) {
      this.setState({ token: accessToken.access_token, loggedIn: true });
      spotifyApi = new SpotifyWebApi();
      spotifyApi.setAccessToken(accessToken.access_token);
      spotifyApi.getMe().then((response) => {
        if (response) {
          this.setState({subscriptionType: response.product});
          logEvent(analytics, 'user', response);
          if (response.product === 'open' || response.product === 'free') {
            logEvent(analytics, 'open_subscription');
            this.initFreeSub();
          }
          if (response.product === 'premium') {
            logEvent(analytics, 'premium_subscription');
            this.playerCheckInterval = setInterval(() => this.checkForPlayer(), 1000);
          }
        }
      });
      window.location.hash = "";

      // console.log('analytics', analytics);
      logEvent(analytics, 'login');
    }
  }

  addToDic (array, track_id, track_features) {
    if (!array[track_id]) {
        array[track_id] = track_features;
    }
  }

  // when we receive a new update from the player
  onStateChanged(state) {
    // TODO: This was put here to reduce # of spotify API calls we make, but it may be messing with the position calculation. Investigate if we still need later.
    setInterval(() => {}, 10);
    // only update if we got a real state
    if (state !== null && state.timestamp !== this.state.apiTimestamp) {
      // Clear error message
      if (this.state.error) {
        this.setState({error: null});
      }
      const {
        current_track: currentTrack,
      } = state.track_window;
      const trackName = currentTrack.name;
      const albumName = currentTrack.album.name;
      const artistName = currentTrack.artists
        .map(artist => artist.name)
        .join(", ");
      const playing = !state.paused;
      this.setState({
        songPosition: state.position,
        songDuration: state.duration,
        trackName,
        albumName,
        artistName,
        playing: playing,
        apiTimestamp: state.timestamp,
      }, () => {
        // Should be playing so start timer
          this.toggleTimer();
      });
      if (this.state.playingTrack?.id !== currentTrack.id) {
        spotifyApi.getTrack(currentTrack.id).then((trackResponse) => {
          var playingTrack = trackResponse;
          this.setState({ playingTrack });
          // console.log('playingTrack', playingTrack);
          // console.log('state from spotify', state);
          // console.log('state from this state', this.state);
          if (!this.state.checked_tracks || !(playingTrack.id in this.state.checked_tracks)) {
            spotifyApi.getAudioFeaturesForTrack(trackResponse.id).then((featuresResponse) => {
              if (featuresResponse) {
                var tmp_dic = this.state.checked_tracks;
                this.addToDic(tmp_dic, playingTrack.id, {
                  key: featuresResponse.key,
                  tempo: featuresResponse.tempo,
                  time_signature: featuresResponse.time_signature,
                  mode: featuresResponse.mode,
                  scale: `${featuresResponse.key}_${featuresResponse.mode}`
                });
                // console.log('audio features', featuresResponse);
                this.setState({ checked_tracks: tmp_dic });
              }
              else {
                  // console.log(`no result in getAudioFeatures for track ${playingTrack.id}`);
              }
            });
          } else {
            // console.log(`${currentTrack.id} has already been cached.`);
          }
        });
      }
    } 
    if (state === null) {
      // state was null, user might have swapped to another device
      this.setState({ error: "Looks like you might have swapped to another device?" });
    }
  }

  toggleTimer() {
    const { playing } = this.state;
    //console.log('toggleTimer playing and counter', playing, counterIsRunning);
    if (playing && !counterIsRunning) {
      this.timer = setInterval(() => {
        counterIsRunning = true;
        const newPosition = this.state.songPosition + 100;
        this.setState({ songPosition: newPosition });
      }, 100);
    } 
    if (!playing) {
      counterIsRunning = false;
      clearInterval(this.timer);
    }
  }

  createEventHandlers() {
    // problem setting up the player
    this.player.on('initialization_error', e => { console.error(e); });
    // problem authenticating the user.
    // either the token was invalid in the first place,
    // or it expired (it lasts one hour)
    this.player.on('authentication_error', e => {
      console.error(e);
      this.setState({ loggedIn: false });
    });
    // currently only premium accounts can use the API
    this.player.on('account_error', e => { console.error(e); });
    // loading/playing the track failed for some reason
    this.player.on('playback_error', e => { console.error(e); });
  
    // Playback status updates
    this.player.on('player_state_changed', state => this.onStateChanged(state));
  
    // Ready
    this.player.on('ready', async data => {
      let { device_id } = data;
      // console.log("Let the music play on!");
      // set the deviceId variable, then let's try
      // to swap music playback to *our* player!
      await this.setState({ deviceId: device_id });
      this.transferPlaybackHere();
    });
  }

  checkForPlayer() {
    const { token } = this.state;

    // if the Spotify SDK has loaded
    if (window.Spotify !== null && this.state.subscriptionType === 'premium') {
      // cancel the interval
      clearInterval(this.playerCheckInterval);
      // create a new player
      this.player = new window.Spotify.Player({
        name: "Jam Along",
        getOAuthToken: cb => { cb(token); },
        volume: 0.5,
      });
      // set up the player's event handlers
      this.createEventHandlers();

      // finally, connect!
      this.player.connect();
    }
  }

  transferPlaybackHere() {
    const { deviceId, token } = this.state;
    // https://beta.developer.spotify.com/documentation/web-api/reference/player/transfer-a-users-playback/
    fetch("https://api.spotify.com/v1/me/player", {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "device_ids": [ deviceId ],
        // true: start playing music if it was paused on the other device
        // false: paused if paused on other device, start playing music otherwise
        "play": true,
      }),
    });
  }

  initFreeSub() {
    // create interval every 1 sec to call get Me() 
    // or get current playback status to display
    setInterval(() => {
      spotifyApi.getMyCurrentPlaybackState({}).then((response) => {
        if (response) {
          this.setState({ deviceId: response.device.id });

          let item = response.item;
          const trackName = item.name;
          const albumName = item.album.name;
          const artistName = item.artists
            .map(artist => artist.name)
            .join(", ");
          
          const playing = response.is_playing;
          this.setState({
            songPosition: response.progress_ms,
            songDuration: item.duration_ms,
            trackName,
            albumName,
            artistName,
            playing: playing,
            apiTimestamp: response.timestamp,
          }, () => {
            // Should be playing so start timer
              this.toggleTimer();
          });
          if (this.state.playingTrack?.id !== item.id) {
            spotifyApi.getTrack(item.id).then((trackResponse) => {
              var playingTrack = trackResponse;
              this.setState({ playingTrack });
              // console.log('playingTrack', playingTrack);
              // console.log('state from spotify', state);
              // console.log('state from this state', this.state);
              if (!this.state.checked_tracks || !(playingTrack.id in this.state.checked_tracks)) {
                spotifyApi.getAudioFeaturesForTrack(trackResponse.id).then((featuresResponse) => {
                  if (featuresResponse) {
                    var tmp_dic = this.state.checked_tracks;
                    this.addToDic(tmp_dic, playingTrack.id, {
                      key: featuresResponse.key,
                      tempo: featuresResponse.tempo,
                      time_signature: featuresResponse.time_signature,
                      mode: featuresResponse.mode,
                      scale: `${featuresResponse.key}_${featuresResponse.mode}`
                    });
                    // console.log('audio features', featuresResponse);
                    this.setState({ checked_tracks: tmp_dic });
                  }
                  else {
                      // console.log(`no result in getAudioFeatures for track ${playingTrack.id}`);
                  }
                });
              } else {
                // console.log(`${currentTrack.id} has already been cached.`);
              }
            });
          }
        } else {
          this.setState({trackName: 'anything on Spotify', artistName: 'beginning playback on any device.'})
        }
      })
    }, 1000);
  }

  togglePlay() {
    this.setState({
      playing: !this.state.playing
    });
  }

  render() {
    const {
      loggedIn,
      trackName,
      artistName,
      error,
      playing,
      checked_tracks,
      playingTrack,
      subscriptionType,
      songDuration,
      songPosition,
    } = this.state;
    
    if (loggedIn) {
      return (
        <Card fluid>
          <Card.Content>
            <Card.Header>Jam Along to {`${trackName} by ${artistName}`}</Card.Header>
            <Card.Description>
              <div className="app">
                <div className="corkboard">
                  <div style={{display:'grid', padding: 'auto', placeItems: 'center'}}>
                    <Fretboard scale={checked_tracks[playingTrack?.id]?.scale} />
                    <div style={{display:'grid', margin: 'auto', columnGap: '20%', gridTemplateColumns: 'auto auto auto'}}>
                      <Rhythm time_signature={checked_tracks[playingTrack?.id]?.time_signature} tempo={checked_tracks[playingTrack?.id]?.tempo} />
                      <Chords scale={checked_tracks[playingTrack?.id]?.scale} mode={checked_tracks[playingTrack?.id]?.mode}/>  
                    </div>
                  </div>
                  <div style={{display:'grid', padding: 'auto', placeItems: 'center', height: 'max-content'}}>
                    <ChordProgressions scale={checked_tracks[playingTrack?.id]?.scale} mode={checked_tracks[playingTrack?.id]?.mode} />
                    <div>
                      {error && <p>Error: {error}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </Card.Description>
            <hr></hr>
            <Card.Content extra>
              <Playback togglePlay={this.togglePlay} toggleTimer={this.toggleTimer} playingTrack={playingTrack} subscriptionType={subscriptionType} player={this.player} songPosition={songPosition} songDuration={songDuration} playing={playing}/>
            </Card.Content>
          </Card.Content>
        </Card>
      );
    } else {
      return (
        <div className="login">
          <p>Sign in to Jam Along!</p>
          <img
            src="https://music-b26f.kxcdn.com/wp-content/uploads/2017/06/635963274692858859903160895_spotify-logo-horizontal-black.jpg"
            alt="Spotify logo"
          />
          <a href={loginUrl} onClick={this.getAndSaveToken()}>LOGIN WITH SPOTIFY</a>
        </div>
      );
    }
  }

}

export default App;
