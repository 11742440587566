import React from "react";
import "./Chords.css";

import { Card } from "semantic-ui-react";

class Chords extends React.Component {

    getHeaders(mode) {
        if (mode) {
            return ['I', 'ii', 'iii', 'IV', 'V', 'vi', 'vii°'];
        }
        return ['i', 'ii°', 'III', 'iv', 'v', 'VI', 'VII'];
    }

    getChords(scale) {
        switch(scale) {
            case '0_0': // C Minor
                return ['Cm', 'D°', 'D♯', 'Fm', 'Gm', 'G♯', 'A♯'];
            case '0_1': // C Major
                return ['C', 'Dm', 'Em', 'F', 'G', 'Am', 'B°'];
            case '1_0': // C♯ Minor
                return ['C♯m', 'D♯°', 'E', 'F♯m', 'G♯m', 'A', 'B'];
            case '1_1': // C♯ Major
                return ['C♯', 'D♯m', 'E♯m', 'F♯', 'G♯', 'A♯m', 'B♯°'];
            case '2_0': // D Minor
                return ['Dm', 'E°', 'F', 'Gm', 'Am', 'A♯', 'C'];
            case '2_1': // D Major
                return ['D', 'Em', 'F♯m', 'G', 'A', 'Bm', 'C♯°'];
            case '3_0': // D♯ Minor
                return ['D♯m', 'E♯°', 'F♯', 'G♯m', 'A♯m', 'B', 'C♯'];
            case '3_1': // D♯ Major
                return ['D♯', 'Fm', 'Gm', 'G♯', 'A♯', 'Cm', 'D°'];
            case '4_0': // E Minor
                return ['Em', 'F♯°', 'G', 'Am', 'Bm', 'C', 'D'];
            case '4_1': // E Major
                return ['E', 'F♯m', 'G♯m', 'A', 'B', 'C♯m', 'D♯°'];
            case '5_0': // F Minor
                return ['Fm', 'G°', 'G♯', 'A♯m', 'Cm', 'C♯', 'D♯'];
            case '5_1': // F Major
                return ['F', 'Gm', 'Am', 'A♯', 'C', 'Dm', 'E°'];
            case '6_0': // F♯ Minor
                return ['F♯m', 'G♯°', 'A', 'Bm', 'C♯m', 'D', 'E'];
            case '6_1': // F♯ Major
                return ['F♯', 'G♯m', 'A♯m', 'B', 'C♯', 'D♯m', 'E♯°'];
            case '7_0': // G Minor
                return ['Gm', 'A°', 'A♯', 'Cm', 'Dm', 'D♯', 'F'];
            case '7_1': // G Major
                return ['G', 'Am', 'Bm', 'C', 'D', 'Em', 'F♯°'];
            case '8_0': // G♯ Minor
                return ['G♯m', 'A♯°', 'B', 'C♯m', 'D♯m', 'E', 'F♯'];
            case '8_1': // G♯ Major
                return ['G♯', 'A♯m', 'Cm', 'C♯', 'D♯', 'Fm', 'G°'];
            case '9_0': // A Minor
                return ['Am', 'B°', 'C', 'Dm', 'Em', 'F', 'G'];
            case '9_1': // A Major
                return ['A', 'Bm', 'C♯m', 'D', 'E', 'F♯m', 'G♯°'];
            case '10_0': // A♯ Minor
                return ['A♯m', 'B♯°', 'C♯', 'D♯m', 'E♯m', 'F♯', 'G♯'];
            case '10_1': // A♯ Major
                return ['A♯', 'Cm', 'Dm', 'D♯', 'F', 'Gm', 'A°'];
            case '11_0': // B Minor
                return ['Bm', 'C♯°', 'D', 'Em', 'F♯m', 'G', 'A'];
            case '11_1': // B Major
                return ['B', 'C♯m', 'D♯m', 'E', 'F♯', 'G♯m', 'A♯°'];
            default:
                return ['N', 'O', 'S', 'C', 'A', 'L', 'E'];
        }
    }

    getTable() {
        return (
            <table>
                <thead>
                    <tr>
                        <th>{this.getHeaders(this.props.mode)[0]}</th>
                        <th>{this.getHeaders(this.props.mode)[1]}</th>
                        <th>{this.getHeaders(this.props.mode)[2]}</th>
                        <th>{this.getHeaders(this.props.mode)[3]}</th>
                        <th>{this.getHeaders(this.props.mode)[4]}</th>
                        <th>{this.getHeaders(this.props.mode)[5]}</th>
                        <th>{this.getHeaders(this.props.mode)[6]}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{this.getChords(this.props.scale)[0]}</td>
                        <td>{this.getChords(this.props.scale)[1]}</td>
                        <td>{this.getChords(this.props.scale)[2]}</td>
                        <td>{this.getChords(this.props.scale)[3]}</td>
                        <td>{this.getChords(this.props.scale)[4]}</td>
                        <td>{this.getChords(this.props.scale)[5]}</td>
                        <td>{this.getChords(this.props.scale)[6]}</td>
                    </tr>
                </tbody>    
            </table>
        );
    }

    getOldChords() {
        return (
            <div className="chords">
                <h4>Chords in Scale</h4>
                {this.getTable()}
            </div>
        );
    }

    getChordsCard() {
        return (
            <Card className="chords" fluid>
                <Card.Content>
                    <Card.Header>Chords in Key</Card.Header>
                    <Card.Description>{this.getTable()}</Card.Description>
                </Card.Content>
            </Card>
        );
    }

    render() {
        if (!this.props.scale) {
            return (<div className="no-chords">No Chords</div>);
        }

        return (
            this.getChordsCard()
        );
    }
}

export default Chords;